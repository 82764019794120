exports.components = {
  "component---src-blogpost-js-content-file-path-src-pages-blog-post-4-mdx": () => import("./../../../src/Blogpost.js?__contentFilePath=/github/workspace/src/pages/blog/post4.mdx" /* webpackChunkName: "component---src-blogpost-js-content-file-path-src-pages-blog-post-4-mdx" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-application-testing-js": () => import("./../../../src/pages/Application-Testing.js" /* webpackChunkName: "component---src-pages-application-testing-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/Apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-augmentation-services-js": () => import("./../../../src/pages/augmentation-services.js" /* webpackChunkName: "component---src-pages-augmentation-services-js" */),
  "component---src-pages-automation-testing-js": () => import("./../../../src/pages/Automation-Testing.js" /* webpackChunkName: "component---src-pages-automation-testing-js" */),
  "component---src-pages-bankierpl-js": () => import("./../../../src/pages/bankierpl.js" /* webpackChunkName: "component---src-pages-bankierpl-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-4-mdx": () => import("./../../../src/pages/blog/post4.mdx" /* webpackChunkName: "component---src-pages-blog-post-4-mdx" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-compatibility-testing-js": () => import("./../../../src/pages/Compatibility-Testing.js" /* webpackChunkName: "component---src-pages-compatibility-testing-js" */),
  "component---src-pages-consulting-services-js": () => import("./../../../src/pages/Consulting-Services.js" /* webpackChunkName: "component---src-pages-consulting-services-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-ctopub-js": () => import("./../../../src/pages/ctopub.js" /* webpackChunkName: "component---src-pages-ctopub-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fractional-cto-js": () => import("./../../../src/pages/fractional-cto.js" /* webpackChunkName: "component---src-pages-fractional-cto-js" */),
  "component---src-pages-functional-testing-js": () => import("./../../../src/pages/Functional-testing.js" /* webpackChunkName: "component---src-pages-functional-testing-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-it-recruitment-services-js": () => import("./../../../src/pages/it-recruitment-services.js" /* webpackChunkName: "component---src-pages-it-recruitment-services-js" */),
  "component---src-pages-it-sourcing-solutions-js": () => import("./../../../src/pages/it-sourcing-solutions.js" /* webpackChunkName: "component---src-pages-it-sourcing-solutions-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-performance-testing-js": () => import("./../../../src/pages/Performance-Testing.js" /* webpackChunkName: "component---src-pages-performance-testing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-qa-services-js": () => import("./../../../src/pages/qa-services.js" /* webpackChunkName: "component---src-pages-qa-services-js" */),
  "component---src-pages-quality-quickstart-quest-js": () => import("./../../../src/pages/quality-quickstart-quest.js" /* webpackChunkName: "component---src-pages-quality-quickstart-quest-js" */),
  "component---src-pages-santander-js": () => import("./../../../src/pages/Santander.js" /* webpackChunkName: "component---src-pages-santander-js" */),
  "component---src-pages-scale-secure-symphony-js": () => import("./../../../src/pages/scale-secure-symphony.js" /* webpackChunkName: "component---src-pages-scale-secure-symphony-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-total-tech-triumph-track-js": () => import("./../../../src/pages/total-tech-triumph-track.js" /* webpackChunkName: "component---src-pages-total-tech-triumph-track-js" */)
}

